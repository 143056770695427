nav {
	min-height: 150px;
	background: #fff;
}

nav .wrap-core-nav-list,
nav .nav-header {
	background: #fff;
}

nav .nav-header {
	min-height: 150px;
}

nav .brand {
	display: block;
	position: relative;
	width: 170px;
	padding: 51px 15px;
	float: left;
}

nav .brand img {
	width: 100%;
}

nav .nav-header .toggle-bar {
	padding: 63px 0;
	float: right;
	margin-right: 15px;
}

nav.core-nav {
	.wrap-core-nav-list {
		.menu.core-nav-list {
			color: #636363;
			text-transform: uppercase;
			font-family: Futura Light;

			li {
				padding: 10px 10px;
				display: block;
				text-decoration: none;

				&.dropdown {
					.fa {
						color: #636363;
					}

					.fa-angle-left {
						display: none;
					}

					.fa-angle-down {
						display: none;
					}

					&.open {
						.fa-angle-right {
							display: none;
						}

						.fa-angle-down {
							display: block;
						}
					}
				}

				a {
					border-bottom: none;
					padding: 0px;

					&.lang {
						color: rgba(168, 168, 168, 1);

						&.ar {
							font-family: GE SS Text Medium, Arial, sans-serif;
							font-size: 20px;
							line-height: 0.8em;
							vertical-align: top;
						}
					}

					&:hover {
						color: $primaryFontColor;
					}
				}

				.dropdown-menu {
					box-shadow: none;
					border-radius: 10px;
					padding: 15px 0;

					li {
						padding: 0;

						&.divider {
							margin-left: 0px;
							margin-right: 0px;
						}

						a {}
					}
				}
			}
		}
	}

}

@media (min-width: 768px) {
	.core-nav .wrap-core-nav-list .core-nav-list {
		padding-top: 0;
	}

	nav.core-nav {
		.wrap-core-nav-list {
			.menu.core-nav-list {
				li {
					padding: 63px 10px;

					&.dropdown {
						.fa {
							position: relative;
							top: 2px;
							right: -5px;
						}

						.fa-angle-left {
							display: none;
						}

						.fa-angle-right {
							display: none;
						}

						.fa-angle-down {
							display: block;
						}
					}

					.dropdown-menu {
						padding: 27px 5px !important;
						left: -116px;
						top: 90px;
						box-shadow: 3px 3px 13px 0px rgba(0, 0, 0, 0.15) !important;

						li {
							&.divider {
								margin-left: 20px;
								margin-right: 20px;
							}

							a {
								display: block;
								padding: 3px 20px;
								white-space: nowrap;
								line-height: 1.278;
							}
						}
					}
				}
			}
		}

	}
}


@media (max-width: 768px) {

	nav .full-container,
	nav .nav-container {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width: 992px) {
	.core-nav .nav-container {
		width: 992px;
		.wrap-core-nav-list .menu.core-nav-list>li {
			padding: 63px 25px;
			.dropdown-menu {
				left: -100px;
			}
		}
	}
}

@media (min-width: 1200px) {
	.core-nav .nav-container {
		width: 1200px;
		.wrap-core-nav-list .menu.core-nav-list>li {
			padding: 63px 25px;
		}
	}
}