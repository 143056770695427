.img-rounded {
    border-radius: 20px;
}

.modal-content {
    .modal-header {
        padding-top: 0;
        padding-bottom: 0;

        .close {
            height: 16px;
            font-size: 34px;
            color: white;
            opacity: 0.8;

            span {
                position: relative;
                left: 45px;
                top: -3px;

            }
        }
    }
    .modal-body {
        .owl-nav.disabled+.owl-dots {
            margin-top: 20px;
            bottom: 37px;
            position: relative;
            height: 0px;
            margin: 0;
}
    }
    .modal-footer {
        border-top: 10px solid #e5e5e500;
        padding-bottom: 10px;

        .col-sm-3.col-md-3.col-lg-3 {
            padding-left: 5px;
            padding-right: 5px;
        }

        .img-responsive.img-rounded {
            margin-bottom: 10px;
        }


    }
}