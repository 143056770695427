.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background: #869791;
    color: #FFF;
    text-decoration: none;
}

.owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px;
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
    border: 1px solid #a8a8a8;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791;
}

@media (min-width: 768px) {

    .owl-carousel,
    .owl-stage-outer,
    .owl-stage,
    .owl-item,
    .item {
        height: 100%;
    }
}

.owl-theme {
    position: relative;

    .owl-item img {
        height: 100%;
        //max-width: 100%;
        width: auto;
    }

    .owl-item img.hidden-lg {
        height: auto;
        width: 100%;
    }

    .owl-video-play-icon {
        height: 80px !important;
    }

    .owl-nav.disabled+.owl-dots {
        margin-top: -5px;

        .owl-dot span {
            background-color: white;
            margin: 5px 5px;
        }

        .owl-dot.active span {
            background-color: $primaryFontColor;
            border: none;
        }
    }
}

.active .img-gallery-wrapper {
    position: relative;
}

.dark-layer-20 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(134, 134, 134, 0.2)
}