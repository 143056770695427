.#{$fa-css-prefix}-facebook:before { content: fa-content($fa-var-facebook); }
.#{$fa-css-prefix}-facebook-f:before { content: fa-content($fa-var-facebook-f); }
.#{$fa-css-prefix}-facebook-messenger:before { content: fa-content($fa-var-facebook-messenger); }
.#{$fa-css-prefix}-facebook-square:before { content: fa-content($fa-var-facebook-square); }
.#{$fa-css-prefix}-instagram:before { content: fa-content($fa-var-instagram); }
.#{$fa-css-prefix}-globe-americas:before { content: fa-content($fa-var-globe-americas); }
.#{$fa-css-prefix}-phone:before { content: fa-content($fa-var-phone); }
.#{$fa-css-prefix}-envelope:before { content: fa-content($fa-var-envelope); }
.#{$fa-css-prefix}-map-signs:before { content: fa-content($fa-var-map-signs); }
.#{$fa-css-prefix}-angle-left:before { content: fa-content($fa-var-angle-left); }
.#{$fa-css-prefix}-angle-right:before { content: fa-content($fa-var-angle-right); }
.#{$fa-css-prefix}-angle-down:before { content: fa-content($fa-var-angle-down); }
.#{$fa-css-prefix}-caret-right:before { content: fa-content($fa-var-caret-right); }
.#{$fa-css-prefix}-bars:before { content: fa-content($fa-var-bars); }