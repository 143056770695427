*,
*:after,
*::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    overflow-x: hidden !important;
}


html,
body,
.wrapper,
.scroller,
.scroller-inner {
    height: 100%;
}

html {
    min-height: 500px;
}

@media (min-width: 768px) {

    html,
    body,
    .core-content,
    .wrapper,
    .scroller,
    .scroller-inner,
    section,
    .color-overlay-1,
    .accessory,
    .color-overlay-2,
    .color-overlay-2 .container,
    .color-overlay,
    .color-overlay-2 .row,
    .color-overlay-2 .row>div,
    article, .article, 
    .thumbnail.text-center {
        height: 100%;
    }

    article {}
}

iframe {
    width: 100%;
}

.scroller,
.scroller-inner {
    position: relative;
}

.wrapper {
    position: relative;
}

article, .article {
    padding: 0 10px 0 10px;

    p {
        text-align: justify;
    }
}

a,
button {
    text-decoration: none;
    outline: none;
}

.article-no-padding {
    padding: 0;
}

.content {
    //padding-top: 145px;
    padding-bottom: 150px;
    margin-bottom: -150px;
    margin-top: -151px;
    padding-top: 151px;
}

@media (min-width: 436px) {
    .content {
        padding-bottom: 123px;
        margin-bottom: -123px;
    }
}

@media (min-width: 768px) {
    .content {
        padding-bottom: 80px;
        margin-bottom: -80px;
    }
}

.color-overlay {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #c7cdd6;
        background-repeat: no-repeat;
    }
}

.color-overlay-1 {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #c7cdd6;
        background-repeat: no-repeat;
    }
}

.color-overlay-2 {
    position: relative;
    padding: 60px 0 60px 0;
    background-color: rgba(255, 255, 255, 0.7);

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
    }
}

@mixin background-pattern {
    background-image: url(../img/element-1.png);
    background-position: left bottom;
    background-size: auto;
    background-repeat: no-repeat;
}

.frontpage {
    height: 100%;

    .owl-carousel,
    .owl-stage-outer,
    .owl-stage,
    .owl-item,
    .item,
    .item-video,
    .color-overlay-2,
    .jumbotron,
    .jumbotron>.row,
    .jumbotron>.row div {
        height: 100%;
    }

    .img-gallery-wrapper {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
    }

    .color-overlay-2 {
        padding: 0;
        background-color: #ccd4da;
    }

    .item-video {
        .color-overlay-2 {
            background-color: black;
        }
    }

    .owl-theme .owl-nav.disabled+.owl-dots {
        bottom: 10px;
        position: relative;
        margin-top: -27px;
    }

    .jumbotron {
        padding: 0;
        margin: 0;
        background: none;

        .headword {
            font-size: 38px;
            font-family: Futura Medium;
            text-transform: uppercase;
            color: white;
            letter-spacing: 10px;
            margin-bottom: 10px;

            &>strong {
                font-family: Futura Bold;
                margin-top: 10px;
            }
        }
    }

    .jumbotron-body {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        @include background-pattern;
        pointer-events: none;

        .container {
            height: auto;

            .text-center {
                max-width: 400px;
            }
        }


    }
}

.about .color-overlay-1:before {
    background-image: url(../img/background-1.jpg);
    background-color: #a8aeb8;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
}

.about .color-overlay-2:before {
    padding: 60px 0 40px 0;
    @include background-pattern;
}

.about {
    .owl-theme .owl-nav.disabled+.owl-dots {
        margin-top: 20px;
    }
}

.thumbnail {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    position: relative;
    text-align: center;
}

.vision-mission {
    .color-overlay-1:before {
        background-image: url(../img/background-3.jpg);
        background-position: center center;
        background-size: auto 100%;
    }

    .color-overlay-2:before {
        @include background-pattern;
    }

    article, .article {
        padding: 0;

        h2:nth-child(3) {
            margin-top: 30px;
        }
    }
}

.divisions {

    .color-overlay-1:before {
        background-image: url(../img/background-3.jpg);
        background-position: center center;
        background-size: auto 100%;
    }

    .color-overlay-2 {
        padding: 20px 0 20px 0;
    }

    .color-overlay-2:before {
        @include background-pattern;
    }

    a {
        color: inherit;
        text-decoration: none;
        height: 100%;
        width: 100%;
        display: inline-block;
    }

    .thumbnail {
        max-height: 320px;

        &>div {
            position: relative;

            &:hover {
                opacity: 0.8;
            }
        }

        img:nth-child(2) {
            position: absolute;
            top: 0;
            right: 0;
        }

        .caption {
            font-size: 21px;
            color: #808285;
            text-transform: uppercase;

            &:hover {
                color: rgb(32, 32, 32);
            }

            p {
                margin-top: 20px;
            }

            h5 {
                font-family: Futura Medium;
                font-size: 21px;
            }
        }
    }
}

@media (min-width: 768px) {
    .divisions {
        .thumbnail-middle {
            padding: 0px 14.2%;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.divisions-facial {
    .color-overlay-1:before {
        background-image: url(../img/background-2.jpg);
        background-color: #afa99e;
        background-position: center center;
        background-size: auto 100%;
    }

    .color-overlay-2:before {
        background-image: url(../img/element-2.png);
        background-position: right top;
    }

    a {
        transition: 0.3s;
        color: inherit;
        text-decoration: none;

        &:hover {
            color: rgb(97, 96, 96);
        }
    }

    img {
        &:hover {
            opacity: 0.9;
        }
    }
}

.divisions-facial,
division-dental {
    .caption {
        padding-top: 0;
    }
}

.divisions-dental {
    .color-overlay-1:before {
        background-image: url(../img/background-4.jpg);
        background-color: white;
        background-position: center center;
        background-size: auto 100%;
    }

    .color-overlay-2:before {
        background-image: url(../img/element-6.png);
        background-position: right top;
    }
}

@media (min-width: 768px) {
    .divisions-dental .color-overlay-1:before {
        background-position: left center;
    }
}

.divisions-nutrition {
    .color-overlay-1:before {
        background-image: url(../img/background-5.jpg);
        background-color: #bbc1cb;
        background-position: right center;
        background-size: auto 100%;
    }

    .color-overlay-2:before {
        background-image: url(../img/element-7.png);
        background-position: right top;
    }
}

.divisions-dental {
    .thumbnail {
        height: 30%;
    }
}

.department {
    .color-overlay-1 {
        &:before {
            background-image: url(../img/background-6.jpg);
            background-color: #dedee0;
            background-position: left center;
            background-size: auto 100%;
        }

        .color-overlay:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #868686;
            opacity: 0.5;
        }

        a {
            text-decoration: none;
        }

        .thumbnail {
            height: 100%;

            img {
                max-width: 100%;
                max-height: 80%;
            }

            .caption {
                color: #808285;
                transition: 0.3s;

                &:hover {
                    color: rgb(53, 53, 53);
                }

                button {
                    font-family: Futura Medium;
                    font-size: 18px;
                    margin-top: 15px;
                    width: 100%;
                    max-width: 180px;
                    ;
                }

                p {
                    margin-top: 10px;
                }

            }
        }
    }
}



.contact {
    height: auto;

    .color-overlay-1:before {
        background-image: url(../img/background-3-vers2.jpg);
        background-position: center center;
        background-size: auto 100%;
        background-color: #dedee0;
    }

    h3 {
        margin-bottom: 25px;
    }

    #map-container {
        box-shadow: 4px 4px 14px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height: 245px;
    }

    .store-icons {
        li {
            margin-bottom: 10px;
        }
    }

    input,
    textarea {
        font-family: Futura Medium;
        font-size: 18px;
        padding: 20px 30px;
        margin-bottom: 30px;
    }

    textarea {
        margin-bottom: 15px;
    }

    .contact-info {
        font-family: Futura Medium;
        padding-top: 20px;

        &>ul>li {
            vertical-align: top;

            button {
                margin-bottom: 30px;
            }
        }

        .btn-lg {
            width: 180px;
        }

        .separator {
            margin-left: 50px;
        }

        .icons {
            .icons-left-col {
                vertical-align: top;
            }

            .icons-bottom-row {
                margin: 20px 0;
            }

            .information {
                margin: 0 25px;
                display: inline-block;
                max-width: 255px;
            }

            .fa {
                vertical-align: top;
                top: 3px;
                position: relative;
                display: inline-block;
            }
        }
    }
}

@media (min-width: 768px) {
    .contact {
        .store-icons {
            margin-top: -165px;
        }
    }
}

@media (max-width: 767px) {
    .g-recaptcha {
        position: relative;
        top: -230px;
    }

    .contact-info button {
        position: relative;
        top: -230px;
    }

    .store-icons {
        position: relative;
        top: 170px;
    }
}


.gallery {
    .color-overlay-1:before {
        background-image: url(../img/background-6.jpg);
        background-color: #dedee0;
        background-position: left center;
        background-size: auto 100%;
    }

    .color-overlay:before {
        background-color: #868686;
        opacity: .5;
    }

    .color-overlay-2 {
        padding: 35px 0;

        .thumbnail-scroll-box {
            height: 90%;

            .thumbnail-scroll-container>.row.text-center {
                height: 50%;

                &>div {
                    height: 100%;

                    &>span.text-center {
                        font-family: MyriadProRegular;
                        font-size: 21px;
                        color: #808285;
                        height: 20%;
                        display: inline-block;
                        width: 100%;
                        display: block;
                        margin: 10px 0 25px;
                        font-size: 25px;
                        text-transform: uppercase;

                        &:hover {
                            color: $primaryFontColor;
                            cursor: pointer;
                        }
                    }
                }

            }
        }

        &>.container>.row {
            height: 10%;

            span {
                color: $primaryFontColor;
                font-size: 22px;
                font-family: Futura Medium;
                cursor: pointer;
                padding-top: 10px;
                position: relative;
                display: inline-block;
            }
        }

    }

    .gallery-item {
        display: block;
        height: 80%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 10px;


        a {
            padding-bottom: 100%;
            width: 100%;
            height: 100%;
            display: inline-block;
            border-radius: 10px;
            background-position: center;
            background-repeat: no-repeat;
            transition: 0.3s;

            &:hover {
                background-image: url(../img/zoom_icon.png);
                background-color: rgba(0, 0, 0, 0.7);
                box-shadow: 5px 5px 10px 0px rgba(74, 74, 74, 0.5);
                background-size: 10%;
            }

            img {
                width: 100%;
                display: none;
            }
        }
    }
}

@media (min-width: 768px) {
    .gallery {
        .color-overlay-2 .container .thumbnail-scroll-box .thumbnail-scroll-container div.row span.text-center {
            font-size: 2vh;
            margin: 0;
        }

        .gallery-item {
            a {
                padding-bottom: 0;
            }
        }
    }
}



.zoom-img-wrapper {
    height: 55%;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;

    &.small-radius {
        border-radius: 20px;
    }

    &:hover .zoom-img {
        -webkit-transform: scale(1.1);
        -webkit-transition: all 0.125s ease-in-out 0s;
        -moz-transition: all 0.125s ease-in-out 0s;
        -ms-transition: all 0.125s ease-in-out 0s;
        -o-transition: all 0.125s ease-in-out 0s;
        transition: all 0.125s ease-in-out 0s;
    }
}

.divisions-nutrition,
.divisions-dental,
.divisions-facial {
    .thumbnail {
        height: 30%;
    }

    .zoom-img-wrapper {
        padding: 0;
        margin: 0;
        background: 0 0;
        border: none;
        text-align: center;
        display: block;
        height: 65%;

        & img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 50%;
        }
    }

    .caption.text-center {
        padding-bottom: 0;
        padding-top: 0;

        & h6 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.padding-35 {
    padding: 35px;
}


.row-flex {
    display: flex;
    flex-flow: row wrap;
}

.wrapper-thumbnails {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: center;
    text-align: center;
    justify-content: center;
    flex-direction: row;
}

.accessory {
    padding-bottom: 60px;
    padding-top: 74px;
    margin-top: -74px
}

.mCustomScrollBox {
    height: 100% !important;
}

.mCSB_container {
    transition: margin 0.3s;
}