@font-face {
    font-family: 'Futura Light';
    src: url('../fonts/FuturaLightC.eot');
    src: url('../fonts/FuturaLightC.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaLightC.woff') format('woff'), url('../fonts/FuturaLightC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Book';
    src: url('../fonts/FuturaBookC.eot');
    src: url('../fonts/FuturaBookC.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaBookC.woff') format('woff'), url('../fonts/FuturaBookC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Medium';
    src: url('../fonts/FuturaMediumC.eot');
    src: url('../fonts/FuturaMediumC.eot?#iefix') format('embedded-opentype'), url('../fonts/FuturaMediumC.woff') format('woff'), url('../fonts/FuturaMediumC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Futura Bold";
    src: url("../fonts/FuturaBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GE SS Text Medium";
    src: url("../fonts/GESSTextMedium.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "MyriadProRegular";
    src: url("../fonts/MyriadProRegular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}