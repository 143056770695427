footer .social-icons .btn {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    outline: 0 !important;
    transition: 0.3s;

    &:hover {
        background-color: white;
        color: #a8a8a8;
        border-color: #a8a8a8;
    }
    &:focus {
        background-color: white;
        color: #a8a8a8;
        border-color: #a8a8a8;
    }
}

.facebook {
    @extend %fa-icon;
    @extend .fab;

    &:before {
        content: fa-content($fa-var-facebook-f);
    }
}

.instagram {
    @extend %fa-icon;
    @extend .fab;

    &:before {
        content: fa-content($fa-var-instagram);
    }
}