footer {
    text-align: center;
    padding: 25px 0;

    hr {
        margin-top: 15px;
        margin-bottom: 0px;
    }

    .col-xs-12.col-sm-7.col-md-6.col-lg-5 span {
        vertical-align: sub;
    }
}