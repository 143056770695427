.breadcrumb {
    position: relative;
    margin-bottom: 0;

    a {
        color: white !important;

        &:hover {
            color: #007da5 !important;
        }
    }


    a,
    li {
        font-family: Futura Medium;
    }
}