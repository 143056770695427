.modal {

    .item,
    .owl-carousel,
    .owl-item,
    .owl-stage,
    .owl-stage-outer {
        height: auto;

        .img-responsive {
            height: auto;
            width: 100%;
        }
    }
    .zoom-img-wrapper.small-radius img:hover {
        transition: 0.3s;
        transform: scale(1.1);
    }
}